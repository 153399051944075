<template>
  <div class="home">
    <top-header @restoreDefault="goShouYe"  :headerShow="headerShow"  :userData="userData"  @dialogVisibleShow="login">
    </top-header>
    
    <div class="deContent">
      <img
        class="text_"
        src="../../../public/static/image/developer/become.png"
      />
      <img class="_top" src="../../../public/static/image/developer/top.png" />
      <div class="_cont">
        <img
          class="_text"
          src="../../../public/static/image/developer/youshi.png"
        />
        <div class="_mid">
          <div class="_box" v-for="(item, index) in list">
            <img
              v-if="index == 0"
              src="../../../public/static/image/developer/firstIcon.png"
            />
            <img
              v-if="index == 1"
              src="../../../public/static/image/developer/secondIcon.png"
            />
            <img
              v-if="index == 2"
              src="../../../public/static/image/developer/thirdIcon.png"
            />
            <img
              v-if="index == 3"
              src="../../../public/static/image/developer/fourthIcon.png"
            />
            <p style="color: #ffffff">{{ item.title }}</p>
            <p style="color: #ffffff; width: 60%; margin: 0px auto">
              {{ item.desc }}
            </p>
          </div>
        </div>
      </div>
      <img class="top1" src="../../../public/static/image/developer/top.png" />
      <div class="btn" @click="goBecome">成为开发者</div>
    </div>
    <dialogLogin @closeDialog="dialogFun" v-if="dialogVisible"></dialogLogin>
  </div>
</template>

<script>
import TopHeader from '@/components/header/TopHeader'
  import {getHeaderToken,getUserId} from "../../plugins/auth";
  import dialogLogin from "../../components/dialogLogin/dialogLogin";
  export default {
  name: "developer",
  components: {
    TopHeader,
    dialogLogin
  },
  data() {
    return {
      //   imgserve:"http://61.185.238.218:25886/pic",
      headerShow: false,
      userData: {},
      dialogVisible: false,
      list: [
        {
          title: "安全稳定",
          desc: "提供安全、可靠、专业的安全合规产品和服务，快速、高效提升您的AI能力 ",
        },
        {
          title: "应用丰富",
          desc: "支持多场景、高并发，产品覆盖征信业务能力广大领域",
        },
        {
          title: "成熟高效",
          desc: "为您提供完整、持续的等征信能力开发服务和等征信可视化服务 ",
        },
        {
          title: "技术支持",
          desc: "提供完善的帮助文档，常设用户支持渠道，快速响应用户需求",
        },
      ],
    };
  },
  created() {
    this.getLocal();
  },
  methods: {
    goProductList() {
      this.$router.push("/conduct");
    },
    getLocal() {
      if(getHeaderToken()){
        this.headerShow = false
        this.$axios.get('/user/annualById?id='+getUserId()).then(res => {
          this.userData = res.data.data
        })
      }else{
        this.userData = {}
        this.headerShow = true
      }
    },
    // login(){
    //   // 点击登录   遮罩消失
    //   this.showmaskLayer = false;
    //   this.dialogVisible = true
    // },
    login() {
      this.dialogVisible = true;
    },
    dialogFun(val) {
      this.dialogVisible = val;
      this.getLocal();
    },
    goUser() {
      this.$router.push("/usercenter");
    },
    goShouYe() {
      this.$router.push("/");
    },
    goBecome() {
      if (this.userData.id != null) {
        if (this.userData.type == 2) {
          // 10.100.70.2:8090/
          let k = window.location.host;
          // if (k == "10.100.70.2:8090") {
          //   window.open("http://10.100.70.3:9090/");
          // } else if (k == "61.185.238.218:25886") {
          //   window.open("http://61.185.238.218:25887/");
          // } else {
          //   window.open("http://61.185.238.218:25887/");
          // }

          window.open("https://www.xj-zhengxin.com/capacityplatform/")
        } else {
          this.$notify({
            title: "失败",
            message: "请先前往个人中心注册成为企业",
            type: "error",
          });
        }
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.deContent {
  position: absolute;
  background-image: url("../../../public/static/image/developer/developerBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-width: 1200px;
  height: 90vh;
  .text_ {
    position: relative;
    top: 6%;
    left: 50%;
    transform: translateX(-50%);
  }
  ._top {
    position: relative;
    top: 14%;
  }
  ._cont {
    position: relative;
    top: 16%;
    width: 100%;
    height: 50%;
    background-image: url("../../../public/static/image/developer/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    ._text {
      position: relative;
      left: 50%;
      top: 10%;
      transform: translateX(-50%);
    }
    ._mid {
      width: 50%;
      margin: 50px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      ._box {
        width: 50%;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  .top1 {
    position: relative;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn {
    position: relative;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 312px;
    height: 68px;
    text-align: center;
    background: #ffffff;
    border-radius: 68px;
    line-height: 68px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #000000;
    cursor: pointer;
  }
}
</style>
