<template>
  <div class="all register-wrapper">
    <div class="header-common-wrapper" style="border-bottom: solid 1px #eeeeee;">
      <div class="header-img-wrapper">
        <img @click="goShouYe" src="../../../src/assets/xinjiangImage/service-platform-img.png">
      </div>
<!--      <div v-if="headerShow" class="header-login-register-wrapper">-->
<!--        <el-button>登录&nbsp;/&nbsp;注册</el-button>-->
<!--      </div>-->
<!--      <div v-if="!headerShow" class="header-login-register-wrapper" style="margin-right: 18.8%; display: flex">-->
<!--        <img-->
<!--          v-if="userData.headImg"-->
<!--          @click="goUser"-->
<!--          style="width: 64px; height: 64px; cursor: pointer"-->
<!--          src="../../../public/static/image/avatar.png"-->
<!--        />-->
<!--      </div>-->

      <div v-if="headerShow" @click="loginSystem" class="header-login-register-wrapper">
        <el-button>登录&nbsp;/&nbsp;注册</el-button>
      </div>
      <div v-if="!headerShow" class="header-login-register-wrapper">
        <el-dropdown @command="handleCommand">
          <img
                  v-if="userData.headImg == null"
                  @click="goUser"
                  style="width: 64px; height: 64px; cursor: pointer"
                  src="../../../public/static/image/avatar.png"
          />
          <img
                  v-if="userData.headImg != null"
                  @click="goUser"
                  style="width: 64px; height: 64px; cursor: pointer"
                  :src="this.$getImageUrl(userData.headImg)"
                  :onerror='this.$defaultimg'
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="contentCard" style="width: 60%">
      <p class="titleText">用户注册</p>
      <el-form ref="form" :model="form" :rules="rules" status-icon label-width="140px" label-position="left" style="margin-top: 20px">
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮件" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="confirmPwd">
              <el-input type="password" v-model="form.confirmPwd" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="企业名称" prop="enterprise_name">
              <el-input v-model="form.enterprise_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码" prop="codes">
              <el-input v-model="form.codes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="公司类型" prop="type">
              <el-select v-model="form.type" placeholder="请选择公司类型" @change="chickvalue">
                <el-option v-for="item in selectArr" :label="item.lable" :value="item.value" :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="organization">
            <!-- <span style="position: absolute; top: 10px; color: red">*</span> -->
            <el-form-item label="机构编码" prop="mechanism" >
              <el-input v-model="form.mechanism"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="企业登记日期">
              <el-col :span="20">
                <el-date-picker type="date" :picker-options="pickerOptions"  placeholder="选择日期" v-model="form.date1" style="width: 100%"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="纳税人识别号">
              <el-input v-model="form.identification"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人代表名称" prop="legal_person_name">
              <el-input v-model="form.legal_person_name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="法人代表电话">
              <el-input v-model="form.phone_call"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人代表身份证号">
              <el-input v-model="form.id_card"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="企业联系地址">
              <el-input v-model="form.address"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="身份证照片" prop="productImg">

              <el-upload ref="upload1" class="avatar-uploader" accept=".png, .jpg, jpeg" action="#"
                         :http-request="httpRequest1"
                         :on-remove="removeCardHead"
                         :limit="1"
                         :file-list="fileList1"
                         :show-file-list="true"
                         :auto-upload="true">
                <img v-if="form.legalpersonCardHead == '' || form.legalpersonCardHead == null" src="../../../public/static/image/rightSide.png" class="cert"/>
                <img v-else :src="form.legalpersonCardHead" class="cert"/>
<!--                <div slot="tip" class="el-upload__tip">-->
<!--                  <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload1">上传</el-button>-->
<!--                </div>-->
              </el-upload>

              <el-upload ref="upload2" class="avatar-uploader" accept=".png, .jpg, jpeg" action="#"
                         :http-request="httpRequest2"
                         :on-remove="removeCardTail"
                         :limit="1"
                         :show-file-list="true"
                         :file-list="fileList2"
                         :auto-upload="true">
                <img v-if="form.legalpersonCardTail == '' || form.legalpersonCardTail == null" src="../../../public/static/image/reverseSide.png" class="cert"/>
                <img v-else :src="form.legalpersonCardTail" class="cert"/>
<!--                <div slot="tip" class="el-upload__tip">-->
<!--                  <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload2">上传</el-button>-->
<!--                </div>-->
              </el-upload>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="营业执照" label-width="130px" prop="businessLicense">
              <el-upload
                      ref="upload3"
                      class="avatar-uploader"
                      accept=".png, .jpg, jpeg"
                      action="#"
                      :http-request="httpRequest3"
                      :on-remove="removeBusinessLicense"
                      :limit="1"
                      :show-file-list="true"
                      :file-list="fileList3"
                      :auto-upload="true"
                      list-type="picture"
              >
                <img v-if="form.businessLicense == '' || form.businessLicense == null"  src="../../../public/static/image/certification.png" class="cert"/>
                <img v-else :src="form.businessLicense" class="cert" style="width: 120px;height:88px;">
                <div slot="tip" class="el-upload__tip">上传营业执照图片，格式jpg、png，大小不超过1M</div>

              </el-upload>
<!--              <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload3">上传</el-button>-->
            </el-form-item>

          </el-col>
        </el-row>

        <el-form-item>
          <p style="text-align: left; line-height: 14px">
            <span style="color: red; font-size: 18px">*</span>
            请仔细阅读用户协议与平台使用规范后再选中
          </p>
          <el-checkbox-group v-model="form.resource">
            <el-checkbox label="1">
              阅读并同意
              <el-button type="text" @click="dialogVisible = true"
                >《用户注册协议》</el-button
              >
            </el-checkbox>
            <el-dialog
              :visible.sync="dialogVisible"
              width="50"
              :before-close="handleClose"
            >
              <h1 style="text-align: center">用户注册协议</h1>
              <span>
                <p class="xieyi">
                  “新疆地方征信平台”（以下简称：本平台）由新疆征信有限责任公司（以下简称“本公司”）开发、运营。您通过点击确认本协议或以其他方式选择接受本协议，即表示您已与本公司达成协议并同意接受本协议的全部约定内容。
                </p>
                <h3>一、服务内容</h3>
                <p class="xieyi">
                  本公司通过国际互联网络等手段为用户提供综合金融服务。本公司有权在必要时修改服务条款，服务条款一旦发生变动，将会在首页上提示修改内容或通过其他形式告知用户。如果用户不同意所改动的内容，可以主动取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。本公司保留随时修改或中断服务而不需知照用户的权利。本公司行使修改或中断服务的权利，不需对用户或第三方负责。本公司发布的通知、公告、声明或其他类似的内容是本协议的一部分。
                </p>
                <h3>二、用户账号</h3>
                <p class="xieyi">
                  用户应按照注册要求填写真实信息。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本平台不承担任何责任。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，请通知本平台。因黑客行为或用户的保管疏忽导致账号非法使用，本平台不承担任何责任。
                </p>
                <h3>三、注册要求</h3>
                <p class="xieyi">
                  1、用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本平台任何页面发布、转载、传送含有下列内容之一的信息，否则本平台有权自行处理并不通知用户：
                </p>
                <p class="xieyi">(1)违反宪法确定的基本原则的；</p>
                <p class="xieyi">
                  (2)危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的；
                </p>
                <p class="xieyi">(3)损害国家荣誉和利益的；</p>
                <p class="xieyi">(4)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                <p class="xieyi">(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p class="xieyi">(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p class="xieyi">
                  (7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
                </p>
                <p class="xieyi">(8)侮辱或者诽谤他人，侵害他人合法权益的；</p>
                <p class="xieyi">
                  (9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
                </p>
                <p class="xieyi">(10)以非法民间组织名义活动的；</p>
                <p class="xieyi">(11)含有法律、行政法规禁止的其他内容的。</p>
                <p class="xieyi">
                  2、用户承诺对其发表或者上传于本平台的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本平台被第三人索赔的，用户应全额补偿本平台一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其他合理费用)；
                </p>
                <p class="xieyi">
                  3、当第三方认为用户发表或者上传于本平台的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本平台发送权利通知书时，用户同意本平台可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本平台将不会自动恢复上述删除的信息；
                </p>
                <p class="xieyi">
                  4、如用户在使用网络服务时违反下述任何规定，本平台有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。
                </p>
                <p class="xieyi">(1)不得为任何非法目的而使用网络服务系统；</p>
                <p class="xieyi">
                  (2)遵守所有与网络服务有关的网络协议、规定和程序；
                </p>
                <p class="xieyi">
                  (3)不得利用本平台进行任何可能对互联网的正常运转造成不利影响的行为；
                </p>
                <p class="xieyi">
                  (4)不得利用本平台进行任何不利于本平台的行为。
                </p>
                <h3>四、隐私保护</h3>
                <p class="xieyi">
                  1、用户注册本公司相关服务时，根据网站要求提供相关企业信息；在您使用本公司服务、参加网站活动、或访问网站网页时，网站自动接收并记录的您浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及您要求取用的网页记录；本公司承诺不公开或透露您的密码、手机号码等在本平台的非公开信息。除非因用户本人的需要、法律或其他合法程序的要求、服务条款的改变或修订等。
                </p>
                <p class="xieyi">
                  2、本平台不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本平台的非公开内容，但下列情况除外：
                </p>
                <p class="xieyi">(1)事先获得用户的明确授权；</p>
                <p class="xieyi">(2)根据有关的法律法规要求；</p>
                <p class="xieyi">(3)按照相关政府主管部门的要求；</p>
                <p class="xieyi">(4)为维护社会公众的利益。</p>
                <p class="xieyi">
                  3、本平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本平台同等的保护用户隐私的责任，则本平台有权将用户的注册资料等提供给该第三方。
                </p>
                <p class="xieyi">
                  4、在不透露单个用户隐私资料的前提下，本平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
                </p>
                <h3>五、责任说明</h3>
                <p class="xieyi">
                  1、本平台无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。
                </p>
                <p class="xieyi">
                  2、本平台不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本平台实际控制的任何网页上的内容，本平台不承担任何责任。
                </p>
                <p class="xieyi">
                  3、对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其他缺陷，本平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
                </p>
                <p class="xieyi">
                  4、本平台有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，本平台对用户和任何第三人均无需承担任何责任。
                </p>
                <p class="xieyi">
                  5、用户通过本公司发生的对接行为以及对接成功后发生的金融活动，由对接双方分别承担相应法律责任，本平台不承担由此引发的法律责任。
                </p>
                <p class="xieyi">
                  6、本平台不承诺用户对接的成功，因任何原因（包括但不限于用户发布的资料、信息不真实、不合法、不完整等）导致对接不成功的，本平台不承担法律责任。
                </p>
                <h3>六、版权说明：</h3>
                <p class="xieyi">
                  任何用户在本平台发表任何形式的信息，均表明该用户主动将该信息的发表权、汇编权、修改权、信息网络传播权无偿独家转让给本公司。本协议已经构成《著作权法》第二十五条所规定的书面协议，并在用户同意接受本注册协议时生效，其效力及于用户此后在本公司发布的任何内容。用户同意并明确了解上述条款，不将已发表于本平台的信息，以任何形式发布或授权其他网站（及媒体）使用。
                </p>
                <h3>七、附则：</h3>
                <p class="xieyi">
                  1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
                </p>
                <p class="xieyi">
                  2、因本协议的履行或本公司的使用产生的争议，双方应协商解决，协商不成的，任何一方均应向西安仲裁委员会申请仲裁解决。
                </p>
                <p class="xieyi">
                  3、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
                </p>
                <p class="xieyi">
                  4、用户勾选协议即视为完全接受本协议，在点击之前请再次确认已知悉并完全理解本协议的全部内容。
                </p></span
              >
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="DialogVisible"
                  >确定承诺</el-button
                >
                <el-button @click="dialogVisible = false">取消</el-button>
              </span>
            </el-dialog>
            <el-checkbox label="2">
              阅读并同意
              <el-button type="text" @click="dialogVisible2 = true"
                >《平台使用规范》</el-button
              >
            </el-checkbox>
          </el-checkbox-group>
          <el-dialog
            :visible.sync="dialogVisible2"
            width="50"
            :before-close="handleClose2"
          >
            <h1 style="text-align: center">平台服务规则</h1>
            <span>
              <p class="xieyi">
                “新疆地方征信平台”（以下简称：本平台）由新疆征信有限责任公司（以下简称“本公司”）开发、运营。您通过点击确认本协议或以其他方式选择接受本协议，即表示您已与本公司达成协议并同意接受本协议的全部约定内容。
              </p>
              <h3>一、服务内容</h3>
              <p class="xieyi">
                本公司通过国际互联网络等手段为用户提供综合金融服务。本公司有权在必要时修改服务条款，服务条款一旦发生变动，将会在首页上提示修改内容或通过其他形式告知用户。如果用户不同意所改动的内容，可以主动取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。本公司保留随时修改或中断服务而不需知照用户的权利。本公司行使修改或中断服务的权利，不需对用户或第三方负责。本公司发布的通知、公告、声明或其他类似的内容是本协议的一部分。
              </p>
              <h3>二、信息采集授权</h3>
              <p class="xieyi">
                1、授权企业在此同意并授权本公司对授权企业相关信息予以采集和查询。所涉企业相关信息包括但不限于如下信息：企业基本信息、企业基础信息、企业情况、企业财务情况、企业资产信息、企业公共信用信息、企业借贷情况、企业司法信息、人民银行企业成员个人征信报告等。
              </p>
              <p class="xieyi">
                2、授权企业在此同意并确认上述“采集和查询”包括从国家机关、政府部门、事业单位、金融机构等相关部门和单位采集上述信息（包括历史信息及更新），也包括从行业协会、社会团体、互联网信息平台渠道等取得上述信息。授权企业在此授权上述有关部门和单位向本公司提供上述信息。
              </p>
              <p class="xieyi">
                3、授权企业在此同意并授权本公司使用本企业的上述信息。
              </p>
              <p class="xieyi">
                4、授权企业在此承诺国家机关、政府部门、事业单位等部门和单位，因行政管理、公共服务的需要，可以直接查询企业的上述信息。本公司根据要求可以向上述部门和单位提供上述信息。
              </p>
              <p class="xieyi">
                5、本委托书所涉全部授权自本授权书委托书签订之日起生效，永久授权。
              </p>
              <p class="xieyi">
                6、授权企业在此声明并确认其已知悉并理解本授权委托书的全部内容，并自愿承担因提供非公开信息及负面信息可能导致的任何不利后果。
              </p>
              <h3>三、用户账号</h3>
              <p class="xieyi">
                用户应按照注册要求填写真实信息。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本平台不承担任何责任。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，请通知本平台。因黑客行为或用户的保管疏忽导致账号非法使用，本平台不承担任何责任。
              </p>
              <h3>四、注册要求</h3>
              <p class="xieyi">
                1、用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本平台任何页面发布、转载、传送含有下列内容之一的信息，否则本平台有权自行处理并不通知用户：
              </p>
              <p class="xieyi">(1)违反宪法确定的基本原则的；</p>
              <p class="xieyi">
                (2)危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的；
              </p>
              <p class="xieyi">(3)损害国家荣誉和利益的；</p>
              <p class="xieyi">(4)煽动民族仇恨、民族歧视，破坏民族团结的;</p>
              <p class="xieyi">(5)破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
              <p class="xieyi">(6)散布谣言，扰乱社会秩序，破坏社会稳定的;</p>
              <p class="xieyi">
                (7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的;
              </p>
              <p class="xieyi">(8)侮辱或者诽谤他人，侵害他人合法权益的;</p>
              <p class="xieyi">
                (9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的;
              </p>
              <p class="xieyi">(10)以非法民间组织名义活动的;</p>
              <p class="xieyi">(11)含有法律、行政法规禁止的其他内容的。</p>
              <p class="xieyi">
                2、用户承诺对其发表或者上传于本平台的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本平台被第三人索赔的，用户应全额补偿本平台一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其他合理费用);
              </p>
              <p class="xieyi">
                3、当第三方认为用户发表或者上传于本平台的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本平台发送权利通知书时，用户同意本平台可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本平台将不会自动恢复上述删除的信息；
              </p>
              <p class="xieyi">
                4、如用户在使用网络服务时违反下述任何规定，本平台有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。
              </p>
              <p class="xieyi">(1)不得为任何非法目的而使用网络服务系统；</p>
              <p class="xieyi">
                (2)遵守所有与网络服务有关的网络协议、规定和程序；
              </p>
              <p class="xieyi">
                (3)不得利用本平台进行任何可能对互联网的正常运转造成不利影响的行为；
              </p>
              <p class="xieyi">(4)不得利用本平台进行任何不利于本平台的行为。</p>
              <h3>五、隐私保护</h3>
              <p class="xieyi">
                1、用户注册本公司相关服务时，根据网站要求提供相关企业信息；在您使用本公司服务、参加网站活动、或访问网站网页时，网站自动接收并记录的您浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及您要求取用的网页记录；本公司承诺不公开或透露您的密码、手机号码等在本平台的非公开信息。除非因用户本人的需要、法律或其他合法程序的要求、服务条款的改变或修订等。
              </p>
              <p class="xieyi">
                2、本平台不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本平台的非公开内容，但下列情况除外：
              </p>
              <p class="xieyi">(1)事先获得用户的明确授权；</p>
              <p class="xieyi">(2)根据有关的法律法规要求；</p>
              <p class="xieyi">(3)按照相关政府主管部门的要求；</p>
              <p class="xieyi">(4)为维护社会公众的利益。</p>
              <p class="xieyi">
                3、本平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本平台同等的保护用户隐私的责任，则本平台有权将用户的注册资料等提供给该第三方。
              </p>
              <p class="xieyi">
                4、在不透露单个用户隐私资料的前提下，本平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
              </p>
              <h3>六、责任说明：</h3>
              <p class="xieyi">
                1、本平台无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。
              </p>
              <p class="xieyi">
                2、本平台不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本平台实际控制的任何网页上的内容，本平台不承担任何责任。
              </p>
              <p class="xieyi">
                3、对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其他缺陷，本平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
              </p>
              <p class="xieyi">
                4、本平台有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，本平台对用户和任何第三人均无须承担任何责任。
              </p>
              <p class="xieyi">
                5、用户通过本公司发生的对接行为以及对接成功后发生的金融活动，由对接双方分别承担相应法律责任，本平台不承担由此引发的法律责任。
              </p>
              <p class="xieyi">
                6、本平台不承诺用户对接的成功，因任何原因（包括但不限于用户发布的资料、信息不真实、不合法、不完整等）导致对接不成功的，本平台不承担法律责任。
              </p>
              <h3>七、版权说明：</h3>
              <p class="xieyi">
                任何用户在本平台发表任何形式的信息，均表明该用户主动将该信息的发表权、汇编权、修改权、信息网络传播权无偿独家转让给本公司。本协议已经构成《著作权法》第二十五条所规定的书面协议，并在用户同意接受本注册协议时生效，其效力及于用户此后在本平台发布的任何内容。用户同意并明确了解上述条款，不将已发表于本平台的信息，以任何形式发布或授权其他网站（及媒体）使用。
              </p>
              <h3>八、附则：</h3>
              <p class="xieyi">
                1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
              </p>
              <p class="xieyi">
                2、因本协议的履行或本公司的使用产生的争议，双方应协商解决，
                协商不成的，任何一方均应向西安仲裁委员会申请仲裁解决。
              </p>
              <p class="xieyi">
                3、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
              </p>
              <p class="xieyi">
                4、用户勾选协议即视为完全接受本协议，在点击之前请再次确认已知悉并完全理解本协议的全部内容。
              </p></span
            >
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="DialogVisible2"
                >确定承诺</el-button
              >
              <el-button @click="dialogVisible2 = false">取消</el-button>
            </span>
          </el-dialog>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submit('form')"
            style="margin-left: 140px"
            >注册</el-button
          >
          <el-button @click="goLogin">返回</el-button>
        </el-form-item>
      </el-form>
      <Vcode :show="isShow" @success="onSuccess" @close="onClose()" />
    </div>

    <foot></foot>
    <dialogLogin @closeDialog="dialogFun" v-if="loginDialogVisible"></dialogLogin>
  </div>
</template>

<script>
  import dialogLogin from "../../components/dialogLogin/dialogLogin";
import foot from '@/components/footer/footer'
import Vcode from 'vue-puzzle-vcode'
  import {getHeaderToken, getUserId, removeHeaderToken, removeUserId} from "../../plugins/auth";
export default {
  name: 'forgetPwd',
  components: {
    foot,
    Vcode,
    dialogLogin
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.newPwd !== '') {
          this.$refs.form.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
      fileList1:[],
      fileList2:[],
      fileList3:[],
      headerShow: false,
      IsUploadSuccess: false,
      organization: false,
      form: {
        phone: '',
        email: '',
        code: '',
        newPwd: '',
        confirmPwd: '',
        enterprise_name: '',
        legal_person_name: '',
        codes: '',
        type: '',
        password: '',
        date1: '',
        id_card: '',
        phone_call: '',
        address: '',
        identification: '',
        mechanism: '',
        rule: '',
        resource: [],
        legalpersonCardHead:'',//正面
        legalpersonCardTail:'',//反面
        businessLicense:''
      },
      loginDialogVisible: false,
      dialogVisible: false,
      dialogVisible2: false,
      selectArr: [{
        lable: '金融机构',
        value: '金融'
      }, {
        lable: '非金融机构',
        value: '非金融'
      }],
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[0-9]{10}$/, message: '手机号格式不正确  ' }],
        email: [
          { required: true, message: '请输入邮件', trigger: 'blur' },
          { pattern: /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/, message: '邮箱号格式不正确  ' }],
        enterprise_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }],
        legal_person_name: [
          { required: true, message: '请输入法人代表名称', trigger: 'blur' }],
        codes: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }, {
            pattrn: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/, message: '格式错误请重新输入'
          }],
        type: [
          { required: true, message: '请选择公司类型', trigger: 'change' }],
        mechanism: [
          { required: true, message: '请输入机构编码', trigger: 'blur' }],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }, {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: '请输入8位以上包括大小写字母、数字及特殊字符组合的密码'
          }],
        confirmPwd: [
          { required: true, validator: validatePass2, trigger: 'blur' }],
        resource: [
          { required: true, message: '请选择用户协议', trigger: 'blur' }],
      },
      userData: {},
      isShow: false,
    }
  },
  created () {
    this.getLocal()
  },
  activated () {
    this.getLocal()
  },
  methods: {
    submitUpload1() {
      this.$refs.upload1.submit();
    },

    submitUpload2() {
      this.$refs.upload2.submit();
    },
    submitUpload3() {
      this.$refs.upload3.submit();
    },
    httpRequest1(params){
      let formdata = new FormData()
      formdata.append('file', params.file);
      this.$axios.post('/common/upload',formdata).then(res=>{
        if(res.data.code == 200){
          this.form.legalpersonCardHead = res.data.url;
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
          // this.$refs.upload1.clearFiles(); //上传成功之后清除历史记录
        }else {
          this.$message.error('上传失败');
        }
      })
    },
    removeCardHead(){
      this.form.legalpersonCardHead = '';
    },
    loginSystem(){
      this.loginDialogVisible = true;
    },
    dialogFun(val) {
      this.loginDialogVisible = val;
      this.getLocal();
    },
    //退出登录
    handleCommand() {
      // console.log("88888888");
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        this.$message({
          type: "success",
          message: "退出成功!",
        });
        removeHeaderToken();
        removeUserId();
        this.getLocal();
      })
      .catch((err) => {
        this.$message({
          type: "info",
          message: "已取消退出",
        });
      });
    },
    httpRequest2(params){
      let formdata = new FormData()
      formdata.append('file', params.file);
      this.$axios.post('/common/upload',formdata).then(res=>{
        if(res.data.code == 200){
          this.form.legalpersonCardTail = res.data.url;
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
        }else {
          this.$message.error('上传失败');
        }
      })
    },
    removeCardTail(){
      this.form.legalpersonCardTail = '';
    },

    httpRequest3(params){
      let formdata = new FormData()
      formdata.append('file', params.file);
      this.$axios.post('/common/upload',formdata).then(res=>{
        if(res.data.code == 200){
          this.form.businessLicense = res.data.url;
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
          // this.$refs.upload3.clearFiles(); //上传成功之后清除历史记录
        }else {
          this.$message.error('上传失败');
        }
      })
    },
    removeBusinessLicense(){
      this.form.businessLicense = '';
    },
    getLocal () {
      if(getHeaderToken()){
        this.headerShow = false
        this.$axios.get('/user/annualById?id='+getUserId()).then(res => {
          this.userData = res.data.data
        })
      }else{
        this.userData = {}
        this.headerShow = true
      }

    },
    chickvalue (value) {
      if (value == '金融') {
        this.organization = true
      } else {
        this.organization = false
      }
    },
    goUser () {
      this.$router.push('/usercenter')
    },
    DialogVisible () {
      this.form.resource.push('1')
      this.dialogVisible = false
    },
    DialogVisible2 () {
      this.form.resource.push('2')
      this.dialogVisible2 = false
    },
    handleClose (done) {
      this.$confirm('确认关闭?')
        .then(_ => {
          done();
        })
        .catch(_ => { })
    },
    handleClose2 (done) {
      this.$confirm('确认关闭?')
        .then(_ => {
          done();
        })
        .catch(_ => { })
    },
    //发送验证码
    // sendCode () {
    //   if (isMobile(this.form.phone)) {
    //     if (this.fastTips == '点击按钮进行验证') {
    //       this.isShow = true
    //     }
    //   } else {
    //     this.fastShow = true
    //   }
    // },
    onSuccess () {
      this.isShow = false // 通过验证后，需要手动隐藏模态框
      this.$axios
        .post('/user/Register', this.form)
        .then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              title: '成功',
              message: '注册成功',
              type: 'success',
            })
            this.resetForm()
            this.$router.replace('/')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
        })
    },
    onClose () {
      this.isShow = false
    },
    goLogin () {
      this.$router.push('/')
    },
    submit (formName) {
      if (this.organization == false) {
        if (this.form.mechanism !== '') {
          this.$set(this.form, 'mechanism', '')
          if (this.form.resource.length == '2') {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.isShow = true
              } else {
                return false;
              }
            });
          } else {
            this.$message.error('请仔细阅读用户协议与平台使用规范后请选中!');
          }
        } else {
          if (this.form.resource.length == '2') {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.isShow = true
              } else {
                return false;
              }
            });
          } else {
            this.$message.error('请仔细阅读用户协议与平台使用规范后请选中!');
          }
        }
      } else {
        // if (this.form.mechanism == '') {
        //   this.$message.error('如你选中公司类型为金融公司,则需要填写机构编码!')
        // } else {
          if (this.form.resource.length == '2') {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.isShow = true
              } else {
                return false;
              }
            });
          } else {
            this.$message.error('请仔细阅读用户协议与平台使用规范后请选中!');
          }
        // }
      }
    },
    //重置表单
    resetForm () {
      this.form = {
        phone: '',
        email: '',
        code: '',
        newPwd: '',
        confirmPwd: '',
        enterprise_name: '',
        legal_person_name: '',
        codes: '',
        type: '',
        password: '',
        date1: '',
        id_card: '',
        phone_call: '',
        address: '',
        identification: '',
        mechanism: '',
        rule: '',
        resource: [],
      }
    },
    goShouYe () {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="less" scoped>

.all {
  background: #ffffff;
}

.all.register-wrapper .avatar-uploader-icon{
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .all.register-wrapper .avatar-uploader .el-upload{
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.all.register-wrapper .avatar-uploader {
  display: inline-block;
  margin: 0 10px;
}
/deep/ .all.register-wrapper .avatar-uploader .el-upload__tip{
  text-align: center;
  font-size: 12px;
  margin-top: 0;
}


.all.register-wrapper .avatar-uploader .cert {
  width: 120px;
  height: 88px;
}


.header {
  width: 100%;
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px 0px #dfdfdf;
}

.headerP {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  margin-left: 16px;
  cursor: default;
}

.headerLeft {
  display: flex;
  align-items: center;
  margin-left: 18.8%;
}

.headerRight {
  margin-right: 18.8%;
  width: 123px;
  height: 48px;
  background: #128bed;
  font-size: 16px;
  color: #ffffff;
  font-family: PingFangSC-Regular;
  display: flex;
  align-items: center;
}

.contentCard {
  width: 30%;
  margin: 50px auto 20px auto;
  box-shadow: 0px 0px 5px 0px #dfdfdf;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 6px;
  .el-form-item {
    margin-bottom: 15px;
  }
  .titleText {
    font-size: 24px;
    text-align: center;
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .rightInput {
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid #e7e7e7;
    text-indent: 20px;
    margin-top: 30px;

    input {
      height: 100%;
      width: 70%;
      line-height: 48px;
      background: none;
      outline: none;
      border: none;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 400;
      text-indent: 16px;
      border-radius: 4px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border: 1px solid #e7e7e7;
    margin-top: 20px;
    border-radius: 4px;

    input {
      height: 100%;
      width: 70%;
      line-height: 48px;
      background: none;
      outline: none;
      border: none;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      //color: #BFBFBF;
      letter-spacing: 0;
      font-weight: 400;
      text-indent: 16px;
      border-radius: 2px;
    }

    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #128bed;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 10px;
      width: 120px;
      cursor: pointer;
      text-align: center;
    }
  }

  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
  }
}
.jiange {
  margin-bottom: 10px;
}
.xieyi {
  text-align: left !important;
  line-height: 18px;
  color: #000 !important;
}
</style>
