<template>
  <div class="all">
    <div class="header-common-wrapper" style="border-bottom: solid 1px #eeeeee;">
      <div class="header-img-wrapper">
        <img @click="goShouYe" src="../../../src/assets/xinjiangImage/service-platform-img.png">
      </div>
      <div v-if="headerShow" @click="login" class="header-login-register-wrapper">
        <el-button>登录&nbsp;/&nbsp;注册</el-button>
      </div>
      <div v-if="!headerShow" class="header-login-register-wrapper" style="margin-right: 18.8%; display: flex">
        <img
                v-if="userData.headImg"
                @click="goUser"
                style="width: 64px; height: 64px; cursor: pointer"
                src="../../../public/static/image/avatar.png"
        />
      </div>
    </div>
    <div class="contentCard">
      <p class="titleText">找回密码</p>
      <div class="rightInput" style="border-radius: 4px">
        <p style="color: #2b2b2b">中国+86 &nbsp;&nbsp;|</p>
        <input v-model="form.phone" placeholder="请手机输入号码" type="text" />
      </div>
      <p
        v-if="fastShow"
        style="
          display: block;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          color: red;
          text-align: left;
        "
      >
        手机号格式不正确,请重新输入
      </p>
      <div class="bottom">
        <input v-model="form.code" placeholder="短信验证码" type="text" />
        <p @click="sendCode">&nbsp;&nbsp; |&nbsp;&nbsp;{{ fastTips }}</p>
      </div>
      <Vcode :show="isShow" @success="onSuccess" />
      <el-input
        style="margin-top: 20px"
        v-model="form.newPwd"
        minlength="12"
        placeholder="新密码"
        type="password"
        show-password
      />
      <el-input
        style="margin-top: 20px"
        @blur="checkPwd"
        v-model="form.confirmPwd"
        minlength="12"
        placeholder="确认密码"
        type="password"
        show-password
      />
      <p
        v-if="checkShow"
        style="
          display: block;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          color: red;
          text-align: left;
        "
      >
        两次输入的密码不一致,请确认后再输入
      </p>
      <p
        v-if="checkAShow"
        style="
          display: block;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          color: red;
          text-align: left;
        "
      >
        密码必须包含字母、数字和符号,长度最小为12位，并且首字母为大写
      </p>
      <p>（注：密码为至少要12位，数字/字母/符号）</p>
      <p
        v-if="errTips != ''"
        style="
          display: block;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          color: red;
          text-align: left;
        "
      >
        {{ errTips }}
      </p>
      <el-button @click="submit" style="width: 100%" type="primary"
        >确定</el-button
      >
      <p
        @click="goLogin"
        style="text-align: left; color: #128bed; cursor: pointer"
      >
        返回登录
      </p>
    </div>

    <div style="width: 100%; height: 10vh"></div>
    <foot></foot>
  </div>
</template>

<script>
import { isMobile } from '@/plugins/validate'
import foot from '@/components/footer/footer'
import Vcode from 'vue-puzzle-vcode'
import {getHeaderToken,getUserId} from "../../plugins/auth";
export default {
  name: 'forgetPwd',
  components: {
    foot,
    Vcode,
  },
  data () {
    return {
      headerShow: false,
      fastTips: '获取验证码',
      form: {
        phone: '',
        code: '',
        newPwd: '',
        confirmPwd: '',
      },
      fastShow: false,
      checkShow: false,
      userData: {},
      errTips: '',
      checkAShow: false,
      isShow: false,
    }
  },
  created () {
    this.getLocal()
  },
  activated () {
    this.getLocal()
  },
  methods: {
    getLocal () {
      if(getHeaderToken()){
        this.headerShow = false
        this.$axios.get('/user/annualById?id='+getUserId()).then(res => {
          this.userData = res.data.data
        })
      }else{
        this.userData = {}
        this.headerShow = true
      }

    },
    goUser () {
      this.$router.push('/usercenter')
    },
    //发送验证码
    sendCode () {
      if (isMobile(this.form.phone)) {
        if (this.fastTips == '获取验证码') {
          this.isShow = true
        }
      } else {
        this.fastShow = true
      }
    },
    //判断新密码与确认密码是否一致
    checkPwd () {
      if (this.form.newPwd != this.form.confirmPwd) {
        this.checkShow = true
      } else {
        this.checkShow = false
      }
    },
    onSuccess () {
      this.isShow = false // 通过验证后，需要手动隐藏模态框
      this.$axios({
            url: "/common/sendSMS",
            method: "post",
            data: { mobile: this.form.phone },
          })
            .then((res) => {
              this.form.code = res.data;
              this.$notify({
                title: "成功",
                message: "验证码发送成功",
                type: "success",
              });
            })
        // .post(`/common/sendSMS?mobile=${this.form.phone}`)
        // .then((res) => {
        //   console.log(res)
        // })
        .catch((err) => {
          // console.log(err)
        })
      let holdTime = 60
      var timer = setInterval(() => {
        if (holdTime > 0) {
          holdTime--
          this.fastTips = '请于' + holdTime + 's后重试'
        }
        if (holdTime <= 0) {
          this.fastTips = '获取验证码'
          clearInterval(timer)
        }
      }, 1000)
    },
    onClose () {
      this.isShow = false
    },
    goLogin () {
      this.$router.push('/')
    },
    submit () {
      // console.log(this.form)
      if (!this.checkShow) {
        if (!/^[A-Z](?=.*?[0-9])(?=.*?[_!@#$%^&*+-.~])[A-Za-z0-9_!@#$%^&*+-.~]{11,30}$/.test(this.form.newPwd)) {
          this.checkAShow = true
        } else {
          this.checkAShow = false
          this.form.password = this.form.newPwd
          this.$axios
            .post('/user/forgetPwd', this.form)
            .then((res) => {
              // console.log(res)
              if (res.data.code == 200) {
                this.$notify({
                  title: '成功',
                  message: '修改密码成功',
                  type: 'success',
                })
                this.resetForm()
              } else {
                this.errTips = res.data.msg
              }
            })
            .catch((err) => {
              // console.log(err)
            })
        }
      }
    },
    //重置表单
    resetForm () {
      this.form = {
        phone: '',
        code: '',
        newPwd: '',
        confirmPwd: '',
        password: '',
      }
    },
    goShouYe () {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="less">
body {
  padding: 0;
  margin: 0;
}
.all {
  background: #ffffff;
}
.header {
  width: 100%;
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px 0px #dfdfdf;
}
.headerP {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  margin-left: 16px;
  cursor: default;
}

.headerLeft {
  display: flex;
  align-items: center;
  margin-left: 18.8%;
}
.headerRight {
  margin-right: 18.8%;
  width: 123px;
  height: 48px;
  background: #128bed;
  font-size: 16px;
  color: #ffffff;
  font-family: PingFangSC-Regular;
  display: flex;
  align-items: center;
}
.contentCard {
  width: 30%;
  margin: 80px auto 0 auto;
  box-shadow: 0px 0px 5px 0px #dfdfdf;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 6px;
  .titleText {
    font-size: 18px;
    text-align: center;
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 20px;
  }
  .rightInput {
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid #e7e7e7;
    text-indent: 20px;
    margin-top: 30px;
    input {
      height: 100%;
      width: 70%;
      line-height: 48px;
      background: none;
      outline: none;
      border: none;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 400;
      text-indent: 16px;
      border-radius: 4px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border: 1px solid #e7e7e7;
    margin-top: 20px;
    border-radius: 4px;
    input {
      height: 100%;
      width: 70%;
      line-height: 48px;
      background: none;
      outline: none;
      border: none;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      //color: #BFBFBF;
      letter-spacing: 0;
      font-weight: 400;
      text-indent: 16px;
      border-radius: 2px;
    }
    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #128bed;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 10px;
      width: 120px;
      cursor: pointer;
      text-align: center;
    }
  }
  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
  }
}
</style>
