<template>
  <div class="customer-info-and-auth">
    <div class="header-common-wrapper" style="border-bottom: solid 1px #eeeeee">
      <div class="header-img-wrapper">
        <img
          @click="goShouYe"
          src="../../../src/assets/xinjiangImage/service-platform-img.png"
        />
      </div>
    </div>
    <div class="contentCard" style="width: 70%">
      <p class="titleText">金融机构和新疆征信服务协议上传</p>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="240px"
        label-position="right"
        style="margin-top: 20px"
      >
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="金融机构名称" prop="bankname">
              <el-input
                v-model="form.bankname"
                placeholder="金融机构名称"
                @blur="getBankNo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金融机构编号" prop="bankno">
              <el-input
                v-model="form.bankno"
                readonly
                placeholder="通过金融机构名称获取金融机构编号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="金融机构和新疆征信服务协议编号" prop="sxzxno">
              <el-input
                v-model="form.sxzxno"
                placeholder="金融机构和新疆征信服务协议编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="金融机构和新疆征信服务起始时间"
              prop="beginTime"
            >
              <el-date-picker
                v-model="form.beginTime"
                style="width: 100%"
                value-format="yyyy-MM-dd"
                placeholder="金融机构和新疆征信服务起始时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="jiange" :gutter="20">
          <el-col :span="12">
            <el-form-item label="金融机构和新疆征信服务终止时间" prop="endTime">
              <el-date-picker
                v-model="form.endTime"
                style="width: 100%"
                value-format="yyyy-MM-dd"
                placeholder="金融机构和新疆征信服务终止时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="financeDualhUploadWrapper">
          <el-col :span="20">
            <el-form-item
              label="上传金融机构和新疆征信服务协议"
              prop="productImg"
            >
              <el-upload
                ref="upload"
                class="avatar-uploader"
                accept=".pdf, .png, .jpg, jpeg"
                action="#"
                :http-request="httpRequest"
                :on-remove="removeFinanceDual"
                :limit="1"
                :show-file-list="true"
                :file-list="fileList"
                :auto-upload="true"
              >
                <img
                  v-if="fileUrls == 'png'"
                  :src="form.uploadFileName"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png/jpeg/pdf文件
                </div>
                <!--                                <p style="text-align: left;" slot="tip" class="el-upload__tip" v-if="fileUrls == 'pdf'">{{pdfFileName}}</p>-->
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button
            type="primary"
            @click="submit('form')"
            style="margin-left: 140px"
            >提交</el-button
          >
          <el-button @click="goLogin">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/footer/footer";
export default {
  name: "financeDual",
  components: {
    foot,
  },
  data() {
    return {
      fileUrls: "",
      pdfFileName: "",
      uploadUrl: process.env.VUE_APP_BASE_API + "/common/upload", //图片上传接口
      fileList: [],
      IsUploadSuccess: false,
      form: {
        bankname: "",
        bankno: "",
        sxzxno: "",
        beginTime: "",
        endTime: "",
        uploadFileName: "",
      },
      rules: {
        bankname: [
          { required: true, message: "请输入金融机构名称", trigger: "blur" },
        ],
        bankno: [
          { required: true, message: "请输入金融机构编号", trigger: "blur" },
        ],
        sxzxno: [
          {
            required: true,
            message: "请输入金融机构和新疆征信服务协议编号",
            trigger: "blur",
          },
        ],
        beginTime: [
          {
            required: true,
            message: "金融机构和新疆征信服务起始时间",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: true,
            message: "金融机构和新疆征信服务终止时间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 上传图片
    submitUpload() {
      this.$refs.upload.submit();
    },

    httpRequest(params) {
      let formdata = new FormData();
      formdata.append("file", params.file);
      this.$axios.post("/common/upload", formdata).then((res) => {
        // console.log(res.data)
        if (res.data.code == 200) {
          this.form.uploadFileName = res.data.url;
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
          if (
            params.file.name
              .substr(params.file.name.lastIndexOf("."))
              .toLowerCase() == ".pdf"
          ) {
            this.fileUrls = "pdf";
            this.pdfFileName = params.file.name;
          } else {
            this.fileUrls = "png";
            this.pdfFileName = "";
          }
          // this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    removeFinanceDual() {
      this.form.uploadFileName = "";
      this.fileUrls = "";
      this.pdfFileName = "";
    },

    //提交
    submit() {
      let _this = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.IsUploadSuccess) {
            this.$axios.post("/bank/insertBankDual", _this.form).then((res) => {
              if (res.data.code == 200) {
                if (res.data.msg == "操作成功") {
                  this.$message.success("保存成功");
                  this.IsUploadSuccess = true;
                  this.$router.push("/");
                } else {
                  this.$message.warning(res.data.msg);
                }
              } else {
                this.$message.error("保存失败");
              }
            });
          } else {
            this.$message("请先将文件或图片上传到服务器");
          }
        }
      });
    },
    checkFile() {
      this.IsUploadSuccess = false;
    },
    goLogin() {
      this.$router.push("/");
    },
    goShouYe() {
      this.$router.push("/");
    },

    getBankNo() {
      let _this = this;
      this.$axios
        .get("/bank/searchBankInfoBh?bankName=" + _this.form.bankname)
        .then((res) => {
          this.form.bankno = res.data.msg;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.customer-info-and-auth {
  background: #ffffff;
}

.customer-info-and-auth .header {
  width: 100%;
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px 0px #dfdfdf;
}

.customer-info-and-auth .headerP {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  margin-left: 16px;
  cursor: default;
}

.customer-info-and-auth .headerLeft {
  display: flex;
  align-items: center;
  margin-left: 18.8%;
}

.customer-info-and-auth {
  .contentCard {
    width: 30%;
    margin: 84px auto 84px auto;
    box-shadow: 0px 0px 5px 0px #dfdfdf;
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 6px;
    .titleText {
      font-size: 24px;
      text-align: center;
      display: block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999;
      letter-spacing: 0;
      font-weight: 400;
      text-align: center;
    }
  }
}
.customer-info-and-auth .jiange {
  margin-bottom: 10px;
}
.customer-info-and-auth .xieyi {
  text-align: left !important;
  line-height: 18px;
  color: #000 !important;
}

::v-deep .financeDualhUploadWrapper .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .financeDualhUploadWrapper .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.financeDualhUploadWrapper .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.financeDualhUploadWrapper .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
