<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
}

.contentTop {
  width: 100%;
  height: 428px;
  // background-image: url("../../../public/static/image/informationPic/ncxybottom.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  ._text {
    position: relative;
    top: 113px;
    left: 361px;

    ._shu {
      width: 10px;
      height: 30px;
      background: #ffffff;
      display: inline-block;
    }

    ._title {
      display: inline-block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-left: 39px;
    }

    ._detail {
      display: block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-top: 29px;
    }

    ._subscribe {
      width: 180px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 25px;
      border: 1px solid #ffffff;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      margin-top: 66px;
    }
  }
}

.desc {
  width: 100%;
  background-image: url("../../../public/static/image/informationPic/xianshangContent.png");
  img {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  ._card {
    margin: 0 18.8% 0 18.8%;
    //height: 100px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 20px 100px;
    font-weight: bold;
    font-size: 22px;
    line-height: 60px;
    border-radius: 10px;
  }
  ._guiHua {
    margin: 0 18.8% 0 18.8%;
    box-sizing: border-box;
    background-color: #ffffff;
  }
}

</style>

<template>
  <div class="home">
    <div class="header-common-wrapper">
      <div class="header-img-wrapper">
        <img @click="goShouYe" src="../../../src/assets/xinjiangImage/service-platform-img.png">
      </div>
      <div v-if="headerShow" @click="login" class="header-login-register-wrapper">
        <el-button>登录&nbsp;/&nbsp;注册</el-button>
      </div>
      <div v-if="!headerShow" class="header-login-register-wrapper">
        <el-dropdown @command="handleCommand">
          <img
            v-if="userData.headImg == null"
            @click="goUser"
            style="width: 64px; height: 64px; cursor: pointer"
            src="../../../public/static/image/avatar.png"
          />
          <img
            v-if="userData.headImg != null"
            @click="goUser"
            style="width: 64px; height: 64px; cursor: pointer"
            :src="this.$getImageUrl(userData.headImg)"
             :onerror='this.$defaultimg'
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="contentTop" :style="style1">
      <div class="_text">
        <div class="_shu" />
        <p class="_title">{{ info.productName }}</p>
        <!--                <p class="_detail"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 帮您更广了解与精准分析合作伙伴信息，助您申<br/>请信用贷款、审批信用贷款等商业决策</p>-->
        <div v-if="IsDingYue == 1" class="_subscribe" @click="quxiaodingyue">
          取消订阅 >>
        </div>
        <div v-else class="_subscribe" @click="dingyue">立即订阅 >></div>
      </div>
    </div>

    <div class="desc">
<!--      <img src="../../../public/static/image/informationPic/XSjieshao.png" />-->
      <div class="product-title-line-wrapper">
        <div class="left"></div>
        <div class="center">农村信用信息共享服务平台</div>
        <div class="right"></div>
      </div>
      <div v-html="showHtml(info.productDesc)" class="_card">
        {{ info.productDesc }}
      </div>
      <img src="../../../public/static/image/informationPic/NCtedian.png" />
      <div class="_guiHua">
        <img
          src="../../../public/static/image/informationPic/xyxx_nongcun_tedian.png"
        />
      </div>
      <img src="../../../public/static/image/informationPic/NCjiagou.png" />
      <div class="_guiHua" style="margin-bottom: 60px">
        <img
          src="../../../public/static/image/informationPic/xyxx_nongcun_jiagou.png"
        />
      </div>
    </div>

    <foot></foot>
    <dialogLogin @closeDialog="dialogFun" v-if="dialogVisible"></dialogLogin>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dialogLogin from "../../components/dialogLogin/dialogLogin";
import foot from "@/components/footer/footer";
import {removeHeaderToken,getHeaderToken,getUserId,removeUserId} from "../../plugins/auth";
export default {
  name: "village",
  components: {
    dialogLogin,
    foot,
  },
  data() {
    return {
      headerShow: false,
      info: {},
      dialogVisible: false,
      IsDingYue: 2,
      userData: {},
      style1:"",
        // imgserve:"http://61.185.238.218:25886/pic",
    };
  },
  computed: {
    ...mapState("user", { token: "token" }),
    ...mapGetters("user", { getToken: "getToken" }),
  },
  created() {
    this.getLocal();
  },
  activated() {
    this.getLocal();
  },
  mounted() {},
  methods: {
    getLocal() {
      if(getHeaderToken()){
        this.headerShow = false
        this.$axios.get('/user/annualById?id='+getUserId()).then(res => {
          this.userData = res.data.data
       this.getProductList();
        }).catch(() => {
          this.getProductList();
        })
      }else{
        this.getProductList();
        this.userData = {}
        this.headerShow = true
      }
    },
    goUser() {
      this.$router.push("/usercenter");
    },
    goShouYe() {
      this.$router.push("/");
    },
    getProductList() {
      let data = {
        userId: this.userData.id,
        productStatus: "1",
      };
      this.$axios
        .post("/product/getProduct", data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 200) {
            let data = res.data.data;
            data.forEach((item, index) => {
              if (item.productName == "农村信用信息共享服务平台") {
                this.info = item;
                this.productId = item.id;
                this.IsDingYue = item.userProductStatus;
                // this.style1 = `background:url("${this.$getImageUrl(item.productImg)}") no-repeat 100%`;
                  this.style1 = `background:url("${this.$getImageUrl(item.productImg)}") no-repeat 100%;background-size:100%;`;
                this.src = this.$getImageUrl(item.productRotationChart)

              }
            });

            this.showHtml(this.info.productDesc);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
    login() {
      this.dialogVisible = true;
    },
    dialogFun(val) {
      this.dialogVisible = val;
      this.getLocal();
    },
    //  取消订阅
    quxiaodingyue() {
      if (this.userData.id != null) {
        let data = {
          userId: this.userData.id,
          productId: this.productId,
        };
        this.$axios.post("/userProduct/cancelProduct", data).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "您成功取消订阅该产品成功",
              type: "success",
            });
            this.getProductList();
          }
        });
      } else {
          this.dialogVisible = true;
      }
    },

    //订阅产品
    dingyue() {
      if (this.userData.id != null) {
        let data = {
          userId: this.userData.id,
          productId: this.productId,
        };
        this.$axios.post("/userProduct/buyProduct", data).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "您订阅该产品成功",
              type: "success",
            });
            this.getProductList();
          }
        });
      } else {
          this.dialogVisible = true;
      }
    },
    //退出登录
    handleCommand() {
      // console.log("88888888");
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>


