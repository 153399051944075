<template>
  <div class="home-page-wrapper" v-loading.fullscreen.lock="loading">
    <maskLayer
      :mask="mask"
      class="maskLayer"
      v-if="showmaskLayer"
      @maskClick="maskClick"
    ></maskLayer>
    <div class="top-header" style="border-bottom: solid 1px #eeeeee">
      <div>
        <div class="platform-wrapper" style="cursor: pointer">
          <img
            @click="goShouYe"
            src="../../../src/assets/xinjiangImage/service-platform-img.png"
            alt=""
          />
        </div>
        <div class="search-result-wrapper">
          <el-input
            size="medium"
            placeholder="请输入企业名称"
            v-model="search.companyName"
          >
            <el-button @click="searchQiYe" slot="append">查一下</el-button>
          </el-input>
        </div>
        <div>
          <div class="login-or-register">
            <div class="login-register-btn" v-if="headerShow">
              <el-button @click="login">登录&nbsp;/&nbsp;注册</el-button>
            </div>
            <el-dropdown
              v-else
              @command="handleCommand"
              class="login-register-btn"
            >
              <img
                v-if="userData.headImg == null"
                @click="goUser"
                style="width: 64px; height: 64px; cursor: pointer"
                src="../../../public/static/image/avatar.png"
              />
              <img
                v-if="userData.headImg != null"
                @click="goUser"
                style="width: 64px; height: 64px; cursor: pointer"
                :src="this.$getImageUrl(userData.headImg)"
                :onerror="this.$defaultimg"
              />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="main-context-wrapper" v-if="infoList.length > 0">
      <div v-for="(item, index) in paginationdatalist" :key="index">
        <el-card shadow="hover">
          <div class="company-info-search-wrapper">
            <div>
              <img
                class="company-logo-img"
                src="../../../public/static/image/gongsilogo.png"
              />
            </div>
            <div class="company-info-text-wrapper">
              <div
                class="divp"
                @click="goDetail(item, index)"
                :style="{ zIndex: zIndex }"
              >
                <div>{{ item.XY010101 }}</div>
              </div>
              <div style="border-bottom: 1px solid #ddd; margin-top: 40px">
                <div class="tag-group">
                <template v-for="item in item.tagLists">
                  <el-tag
                    v-if="!!item.tagName"
                    :key="item.tagName"
                    :type="item.tagType"
                    color=""
                    size="small"
                    effect="dark"
                  >
                    {{ item.tagName }}
                  </el-tag>
                </template>
              </div>
                <el-descriptions>
                  <el-descriptions-item label="法定代表人"
                    ><span class="text-color">{{
                      item.XY010105 ? item.XY010105 : "--"
                    }}</span></el-descriptions-item
                  >
                  <el-descriptions-item label="注册资本">
                    <span class="money-wrapper" v-if="item.XY010113"
                      >{{ item.XY010113 }}万元人民币</span
                    >
                    <span class="money-wrapper" v-else>--</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="成立日期">{{
                    item.XY010117 ? item.XY010117 : "--"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="电话">{{
                    item.XY010121 ? item.XY010121 : "--"
                  }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="地址">{{
                    item.XY010104 ? item.XY010104 : "--"
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
              <div style="margin-top: 14px">
                <el-descriptions>
                  <el-descriptions-item label="网址名称"
                    ><span class="text-color">{{
                      item.XY010101
                    }}</span></el-descriptions-item
                  >
                  <el-descriptions-item label="微信公众号">{{
                    item.XY010101
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
              <div class="legal-person-wrapper">
                <div class="yellow-color">
                  {{ item.XY010105 ? item.XY010105.charAt(0) : "--" }}
                </div>
                <div class="legal-person-name">
                  <p>{{ item.XY010105 }}</p>
                  <p class="person-position">{{ item.XY010110 }}</p>
                </div>
              </div>
              <div class="bottom-line">
                <span>股东信息</span>
                <span>主要人员</span>
                <span>最终受益人</span>
                <span>变更记录</span>
                <span>经营信息</span>
                <span>法律诉讼</span>
                <span>经营风险</span>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <div class="pagination-wrapper" v-if="infoList.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="infoNone" v-if="infoList.length == 0">
      <img src="../../assets/no-data.png" />
      <p style="color: #8a8a8a">暂时没有找到相关数据</p>
    </div>

    <foot v-if="infoList.length <= 1" class="special-foot"></foot>
    <foot v-if="infoList.length > 1"></foot>
    <dialogLogin v-if="dialogVisible" @closeDialog="dialogFun"></dialogLogin>
  </div>
</template>

<script>
import dialogLogin from "@/components/dialogLogin/dialogLogin";
import foot from "@/components/footer/homeFooter";
import maskLayer from "@/components/maskLayer/maskLayer";
import {
  removeHeaderToken,
  getHeaderToken,
  getUserId,
  removeUserId,
} from "../../plugins/auth";

export default {
  name: "Home",
  components: {
    dialogLogin,
    foot,
    maskLayer,
  },
  data() {
    return {
      zIndex: 9,
      showmaskLayer: false, //注册登录默认指引框
      mask: {
        title: "点击公司名称，查看信用报告!",
        showNext: true,
        maskTop: "180px",
        maskLeft: "160px",
        messtop: "180px",
        messleft: "360px",
      },
      defaultimgsrc:
        "this.src='" + require("../../../public/static/image/avatar.png") + "'",
      currentPage: 1,
      total: 0,
      pagesize: 20,
      paginationdatalist: [],
      searchList: [
        { id: 1, name: "查企业" },
        { id: 2, name: "查老板" },
      ],
      tabIndex: 0,
      headerShow: false,
      userData: {},
      dialogVisible: false,
      infoList: [],
      search: {
        companyName: this.$route.query.searchKeyword,
      },
      fromPagination: false,
      loading: true,
      // loading: false
    };
  },
  watch: {
    // 监听showmaskLayer
    showmaskLayer: {
      handler(newName, oldName) {
        // console.log(newName,oldName,'------')
        if (newName) {
          let body = document.getElementsByTagName("body");
          // console.log(body,'99')
          body[0].setAttribute("style", "overflow :hidden");
          this.zIndex = 9;
        } else {
          let body = document.getElementsByTagName("body");
          // console.log(body,'99')
          body[0].setAttribute("style", "overflow :auto");
          this.zIndex = 1;
        }
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    this.getLocal();
    this.getList();
    let maskLayer = JSON.parse(sessionStorage.getItem("maskLayer"));
    let maskLayerNum = JSON.parse(sessionStorage.getItem("maskLayerNum"));
    // console.log(maskLayer,maskLayerNum,'maskLayer应该查2')
    if (this.infoList.length > 0) {
      if (maskLayer) {
        this.zIndex = 1;
        this.showmaskLayer = false;
      } else {
        if (maskLayerNum == 2) {
          this.showmaskLayer = true;
        }
        // 点击关闭 或者最后一步改为
        // sessionStorage.setItem('maskLayer',JSON.stringify(true))
      }
    } else {
      this.showmaskLayer = false;
    }
  },
  activated() {
    this.getLocal();
    this.getList();
  },
  mounted() {},
  methods: {
    //企业特色标签
    getToday() {
      const today = new Date();
      return today.format("yyyy-MM-dd");
    },
    async  getEntTags(item,infoListIndex) {
      /**
       * //高新技术企业-HTECH_CERT
      //TECH_SMC-科技型中小企业，
      //自治区重点实验室-STD_LABBUILD
       * // { type: "", label: "高新技术企业" },
        // { type: "info", label: "科技型中小企业" },
        // { type: "", label: "新疆自治区重点实验室" },
       */
      let that = this;
      const tagLists=[]
      let tagsName = [
        { type: "HTECH_CERT", name: "高新技术企业" },
        { type: "TECH_SMC", name: "科技型中小企业" },
        { type: "STD_LABBUILD", name: "新疆自治区重点实验室" },
      ];

      await Promise.all(tagsName.map(async (tagName, index) => {
        console.log(index,'map2');
        
        await  this.$axios
          .post(`/search/baseService/${tagName.type}`, {
            socialCreditCode: item.XY010133, //this.info.XY010133
          })
          .then(async(res) => {
            // console.log(res, "标签");
            if (res && res.data && res.data.data && res.data.data.size > 0) {
              // console.log(res.data.data.data, "res.data.data.data");
              tagLists[index] = res.data.data.data[0];
              tagLists[index].tagName = tagName.name;
              if (index == 0 || index == 1) {
                tagLists[index].tagType =
                tagLists[index].yxq >= this.getToday() ? "" : "info";
              } else if (index == 2) {
                tagLists[index].yxq = this.getToday();
                tagLists[index].tagType =
                tagLists[index].yxq >= this.getToday() ? "" : "info";
              }
            } else {
              tagLists[index] = {};
            }
          });
      }));
      this.infoList[infoListIndex].tagLists=tagLists
      
    },
    // 点击指引框
    maskClick(v) {
      if (v.type == "close") {
        // 关闭指引框
        this.showmaskLayer = false;
        // sessionStorage.setItem('maskLayer',JSON.stringify(true))
        sessionStorage.setItem("maskLayerNum", JSON.stringify(3));
      } else {
        sessionStorage.setItem("maskLayerNum", JSON.stringify(3));
        const obj = JSON.stringify(this.paginationdatalist[0]);
        window.sessionStorage.setItem("obj", obj);
        this.$router.push("/lawsuit");
      }
    },
    changeList(index) {
      // console.log(index);
      this.tabIndex = index;
    },
    goDetail(item, index) {
      if (this.userData.id != null) {
        let data = {
          userId: this.userData.id,
          businessName: item.XY010101,
          legalPersion: item.XY010105,
          registerDate: item.XY010117,
          // businessStatus: item.XY010132,
          business11: item.XY010132,
        };
        this.$axios.post("/history/setBrowseHistory", data).then((res) => {
          // console.log(res);
        });
      }

      const obj = JSON.stringify(item);
      window.sessionStorage.setItem("obj", obj);
      this.$router.push("/lawsuit");
    },
    goUser() {
      this.$router.push("/usercenter");
    },
    goShouYe() {
      this.$router.push("/");
    },
    getLocal() {
      if (getHeaderToken()) {
        this.headerShow = false;
        this.$axios.get("/user/annualById?id=" + getUserId()).then((res) => {
          this.userData = res.data.data;
        });
      } else {
        this.userData = {};
        this.headerShow = true;
      }
    },
    async   getList() {
      let item = window.sessionStorage.getItem("searchResult");
      let searchResult = JSON.parse(item);
      this.infoList = searchResult.data;
      await Promise.all(this.infoList.map(async (item, index0) => {
        console.log(index0,'map1');
        await  this.getEntTags(item,index0)
      }))
      
      this.paginationdatalist = this.infoList;
      this.total = searchResult.total;
      this.loading = false;
    },
    login() {
      this.dialogVisible = true;
    },
    dialogFun(val) {
      // console.log(val);
      this.dialogVisible = val;
      this.getLocal();
    },
    searchQiYe(fromPagination) {
      if (this.search.companyName == "") {
        this.$message("请输入需要查询的关键字");
      } else {

        if (!this.fromPagination) {
          this.pagesize = 20;
          this.currentPage = 1;
        }
        this.search.pageSize = this.pagesize;
        this.search.pageNo = this.currentPage;
        this.loading = true;
        this.$axios.post("/search/business", this.search).then(async(res) => {
          if (res.data.code == 200 && res.data.data.code == 200) {
            let searchResult = JSON.stringify(res.data.data);
            window.sessionStorage.setItem("searchResult", searchResult);
            this.infoList = res.data.data.data;
            await Promise.all(this.infoList.map(async (item, index0) => {
              await  this.getEntTags(item,index0)
            }))
            this.paginationdatalist = this.infoList;
            this.total = res.data.data.total;
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false
        })
        this.fromPagination = false;
      }
    },
    //分页当前页
    handleCurrentChange(res) {
      this.currentPage = res;
      this.fromPagination = true;
      this.searchQiYe();
    },
    //分页单页大小变化时
    handleSizeChange(res) {
      this.pagesize = res;
      this.fromPagination = true;
      this.searchQiYe();
    },
    errorimg(event) {
      // console.log(event);
      let img = event.srcElement;
      img.src = "../../../public/static/image/avatar.png";
      img.onerror = null;
    },
    //退出登录
    handleCommand() {
      // console.log("88888888");
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.main-context-wrapper {
  min-width: 1200px;
  padding: 40px 0;
  & > div {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 15px;
    .company-info-search-wrapper {
      display: flex;
      .company-logo-img {
        width: 122px;
        height: 122px;
      }
      .company-info-text-wrapper {
        position: relative;
        padding: 0 20px;
        .tag-group {
          .el-tag {
            margin-right: 10px;
          }
        }
        .text-color {
          color: rgba(18, 139, 237);
          font-weight: bold;
        }
        .money-wrapper {
          color: rgba(102, 102, 102);
          font-weight: bold;
        }
        .el-descriptions-item__container .el-descriptions-item__label {
          color: #78909c;
        }
        .divp {
          background-color: #fff;
          font-family: PingFangSC-Medium;
          font-size: 20px;
          position: absolute;
          top: 10px;
          color: #e33244;
          letter-spacing: 0;
          font-weight: 500;
          cursor: pointer;
          margin-top: 0px;
        }
        .legal-person-wrapper {
          display: flex;
          width: 242px;
          box-sizing: border-box;
          border: solid 1px #dddddd;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 40px;
          .yellow-color {
            width: 56px;
            height: 56px;
            background: #feb300;
            border-radius: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 28px;
            line-height: 50px;
            font-weight: 400;
          }
          .legal-person-name {
            p {
              margin: 5px 16px;
              font-size: 16px;
              color: #424242;
            }
            .person-position {
              font-size: 14px;
              color: #8a8a8a;
            }
          }
        }
        .bottom-line {
          span {
            width: 56px;
            height: 20px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #606266;
            letter-spacing: 0;
            font-weight: 400;
            margin-right: 2rem;
          }
        }
      }
    }
  }
}
.pagination-wrapper {
  width: 100%;
  height: 60px;
  text-align: center;
  margin: 0 auto;
}
.infoNone {
  padding-top: 80px;
  text-align: center;
}

.special-foot.home-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
