<template>
  <div class="home">
    <div class="header-common-wrapper">
      <div class="header-img-wrapper">
        <img
          @click="goShouYe"
          src="../../../src/assets/xinjiangImage/service-platform-img.png"
        />
      </div>
      <div v-if="headerShow" class="header-login-register-wrapper">
        <el-button>登录&nbsp;/&nbsp;注册</el-button>
      </div>
      <div v-if="!headerShow" class="header-login-register-wrapper newlogin">
        <img
          v-if="userData.headImg == null"
          style="width: 64px; height: 64px; cursor: pointer"
          src="../../../public/static/image/avatar.png"
        />
        <img
          v-else
          style="width: 64px; height: 64px; cursor: pointer"
          :src="this.$getImageUrl(userData.headImg)"
          :onerror="this.$defaultimg"
        />
        <p @click="outLogin" class="loginOutNew">退出登录</p>
      </div>
    </div>

    <el-container class="contentMid" style="margin-bottom: 60px">
      <el-aside class="sideBar">
        <el-row class="tac">
          <el-col>
            <el-menu
              default-active="1"
              @select="changeSideBar"
              class="el-menu-vertical-demo"
            >
              <el-menu-item index="1" class="item" align="center">
                <span slot="title">个人中心</span>
              </el-menu-item>
              <el-menu-item index="2" class="item" align="center">
                <span slot="title">订购记录</span>
              </el-menu-item>
              <el-menu-item index="3" class="item" align="center">
                <span slot="title">使用记录</span>
              </el-menu-item>
              <el-menu-item index="4" class="item" align="center">
                <span slot="title">浏览历史</span>
              </el-menu-item>
              <!--                          <el-menu-item index='5' class="item" align="center">-->
              <!--                              <span slot="title">我的笔记</span>-->
              <!--                          </el-menu-item>-->
              <el-menu-item index="6" class="item" align="center">
                <span slot="title">我的收藏</span>
              </el-menu-item>
              <!-- <el-menu-item index="7" class="item" align="center">
                              <span slot="title">我的关注</span>
                            </el-menu-item> -->
            </el-menu>
          </el-col>
        </el-row>
        <!--              <el-tabs tab-position="left" style="height: 200px;">-->
        <!--                  <el-tab-pane label="用户管理">用户管理</el-tab-pane>-->
        <!--                  <el-tab-pane label="配置管理">配置管理</el-tab-pane>-->
        <!--                  <el-tab-pane label="角色管理">角色管理</el-tab-pane>-->
        <!--                  <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>-->
        <!--              </el-tabs>-->
      </el-aside>
      <el-container>
        <el-header class="containerTop">
          <img
            v-if="userData.headImg == null"
            style="width: 64px; height: 64px"
            src="../../../public/static/image/avatar.png"
          />
          <img
            v-if="userData.headImg != null"
            style="width: 64px; height: 64px"
            :src="this.$getImageUrl(userData.headImg)"
            :onerror="this.$defaultimg"
          />
          <div style="margin-left: 20px">
            <p
              style="
                font-family: PingFangSC-Medium;
                font-size: 20px;
                color: #2e2e2e;
                font-weight: 500;
              "
            >
              {{ userData.username }}
            </p>
            <p
              style="
                font-family: PingFangSC-Regular;
                font-size: 16px;
                color: #128bed;
                letter-spacing: 0;
                font-weight: 400;
              "
            >
              {{ userData.phone }}
            </p>
          </div>
          <p v-if="userData.type == 1" class="containerTopText">个人</p>
          <p v-if="userData.type == 2" class="containerTopText">企业</p>
          <!-- <p @click="outLogin" class="loginOut">退出登录</p> -->
        </el-header>
        <el-main style="width: 100%">
          <el-row class="main">
            <div v-if="sideBarIndex == 1">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="个人信息" name="first" class="pane">
                  <el-form :model="userForm" label-position="left" ref="form">
                    <el-form-item
                      label="登录名"
                      label-width="90px"
                      align="center"
                    >
                      <el-input
                        v-model="userForm.username"
                        disabled
                        placeholder="请输入登录名"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="手机号"
                      label-width="90px"
                      align="center"
                    >
                      <el-input
                        @blur="checkPhone"
                        v-model="userData.phone"
                        disabled
                        placeholder="请输入手机号"
                      ></el-input>
                    </el-form-item>
                    <p v-if="phoneShow" class="tipsText">
                      手机号格式不正确,请重新输入
                    </p>
                    <el-form-item
                      label="邮箱"
                      label-width="90px"
                      align="center"
                    >
                      <el-input
                        @blur="checkEmail"
                        v-model="userForm.email"
                        placeholder="请输入邮箱"
                      ></el-input>
                    </el-form-item>
                    <p v-if="emailShow" class="tipsText">
                      邮箱格式不正确,请重新输入
                    </p>
                    <el-form-item
                      label="真实姓名"
                      label-width="90px"
                      align="center"
                    >
                      <el-input
                        v-model="userForm.realName"
                        placeholder="请输入真实姓名"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="上传头像"
                      align="center"
                      class="avatarWrapper"
                    >
                      <el-upload
                        class="avatar-uploader"
                        :http-request="avtarUpload"
                        ref="avatarupload"
                        :on-remove="removeAvatar"
                        :limit="1"
                        action="#"
                        :show-file-list="true"
                        :file-list="fileList2"
                        :auto-upload="true"
                        :before-upload="beforeAvatarUpload"
                      >
                        <img
                          v-if="userForm.headImg"
                          :src="userForm.headImg"
                          class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                    <el-button
                      style="margin-top: 6px"
                      @click="userSubmit"
                      type="primary"
                      >保存</el-button
                    >
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="密码修改" name="second" class="pane">
                  <el-form
                    :model="pwdForm"
                    ref="pwdForm"
                    label-position="left"
                    :rules="pwdFormRules"
                    label-width="140px"
                  >
                    <el-form-item label="新密码" prop="password">
                      <el-input
                        v-model="pwdForm.password"
                        placeholder="请输入新密码"
                        show-password
                      />
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="confirmPwd">
                      <el-input
                        v-model="pwdForm.confirmPwd"
                        placeholder="请输入新密码"
                        show-password
                      />
                    </el-form-item>
                    <el-form-item
                      label="获取动态验证码"
                      prop="verifyCode"
                      class="validate-code-wrapper"
                    >
                      <el-input
                        v-model="pwdForm.verifyCode"
                        placeholder="请输入动态验证码"
                        style="width: 80%"
                      ></el-input>
                      <el-button
                        type="primary"
                        :disabled="isDisabled"
                        style="
                          width: 20%;
                          padding: 12px 2px;
                          border-top-left-radius: 0;
                          border-bottom-left-radius: 0;
                        "
                        @click="getPhoneCode"
                        >{{ getCodeText }}</el-button
                      >
                    </el-form-item>
                    <el-form-item label-width="0px" style="text-align: center">
                      <el-button @click="resetForm" style="margin-right: 20px"
                        >重 置</el-button
                      >
                      <el-button @click="pwdSubmit" type="primary"
                        >保 存</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="企业认证" name="third" class="pane">
                  <!-- :rules="rules"
                                    ref="enterprise" -->
                  <el-form
                    :model="enterprise"
                    label-position="left"
                    label-width="120px"
                    :rules="rules"
                    ref="enterpriseForm"
                  >
                    <el-form-item
                      label="审核状态"
                      label-width="130px"
                      prop="auditStatus"
                    >
                      <el-input
                        v-model="enterprise.auditStatus"
                        placeholder="审核状态"
                        disabled
                      ></el-input>
                      <!--                      <el-select v-model="enterprise.auditStatus" placeholder="审核状态" disabled>-->
                      <!--                        <el-option v-for="dict in approveOptions" :label="dict.dictLabel" :value="dict.dictValue" :key="dict.dictValue"></el-option>-->
                      <!--                      </el-select>-->
                    </el-form-item>
                    <el-form-item
                      label="企业名称"
                      label-width="130px"
                      prop="businessName"
                    >
                      <el-input
                        v-model="enterprise.businessName"
                        placeholder="请输入企业名称"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="法人姓名"
                      label-width="130px"
                      prop="legalperson"
                    >
                      <el-input
                        v-model="enterprise.legalperson"
                        placeholder="请输入法人姓名"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="法人身份证号"
                      label-width="130px"
                      prop="legalpersonid"
                    >
                      <el-input
                        v-model="enterprise.legalpersonid"
                        placeholder="请输入法人身份证号"
                      ></el-input>
                    </el-form-item>
                    <p v-if="IdShow" class="tipsText" style="margin-left: 50px">
                      身份证号码不正确,请检查后重新输入
                    </p>
                    <el-form-item
                      label="企业登记日期"
                      label-width="130px"
                      prop="businessRegisterTime"
                    >
                      <el-date-picker
                        v-model="enterprise.businessRegisterTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                      />
                    </el-form-item>
                    <el-row>
                      <el-col :span="24" class="all register-wrapper">
                        <el-form-item
                          label="身份证照片"
                          prop="productImg"
                          v-if="enterprise.auditStatus != '2'"
                        >
                          <el-upload
                            ref="upload1"
                            class="avatar-uploader"
                            accept=".png, .jpg, jpeg"
                            action="#"
                            :http-request="httpRequest1"
                            :limit="1"
                            :file-list="fileList1"
                            :on-remove="removeCardHead"
                            :show-file-list="true"
                            :auto-upload="true"
                          >
                            <img
                              v-if="
                                enterprise.legalpersonCardHead == '' ||
                                enterprise.legalpersonCardHead == null
                              "
                              src="../../../public/static/image/rightSide.png"
                              class="cert"
                            />
                            <img
                              v-else
                              :src="enterprise.legalpersonCardHead"
                              class="cert"
                            />
                          </el-upload>

                          <el-upload
                            ref="upload2"
                            class="avatar-uploader"
                            accept=".png, .jpg, jpeg"
                            action="#"
                            :http-request="httpRequest2"
                            :on-remove="removeCardTail"
                            :limit="1"
                            :show-file-list="true"
                            :file-list="fileList2"
                            :auto-upload="true"
                          >
                            <img
                              v-if="
                                enterprise.legalpersonCardTail == '' ||
                                enterprise.legalpersonCardTail == null
                              "
                              src="../../../public/static/image/reverseSide.png"
                              class="cert"
                            />
                            <img
                              v-else
                              :src="enterprise.legalpersonCardTail"
                              class="cert"
                            />
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :span="24" class="all register-wrapper">
                        <el-form-item label="营业执照" prop="businessLicense">
                          <el-upload
                            class="avatar-uploader"
                            ref="upload"
                            accept=".png, .jpg, jpeg"
                            action="#"
                            :http-request="httpRequest"
                            :on-remove="removeBusinessLicense"
                            :limit="1"
                            :show-file-list="true"
                            :file-list="fileList"
                            :auto-upload="true"
                          >
                            <img
                              v-if="
                                enterprise.businessLicense == '' ||
                                enterprise.businessLicense == null
                              "
                              src="../../../public/static/image/certification.png"
                              class="cert"
                            />
                            <img
                              style="width: 100%"
                              v-else
                              :src="enterprise.businessLicense"
                              class="cert"
                            />
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-form-item>
                      <el-button
                        @click="enterpriseSubmit()"
                        type="primary"
                        style="float: right"
                      >
                        保存
                      </el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div v-if="sideBarIndex == 2">
              <p
                style="
                  width: 96%;
                  margin: 0px auto;
                  padding: 20px 0;
                  border-bottom: 1px solid #eaeaea;
                  font-family: PingFangSC-Semibold;
                  font-size: 18px;
                  color: #424242;
                  font-weight: 600;
                "
              >
                订购记录
              </p>
              <el-table
                :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                :data="tableData"
                style="width: 96%; margin: 0px auto"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="buyProductName"
                  label="产品名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="buyTime"
                  label="购买时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="endTime"
                  label="到期时间"
                  align="center"
                ></el-table-column>
                <el-table-column prop="buyType" label="订购类型" align="center">
                  <template slot-scope="scope">
                    <span
                      style="
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        color: #128bed;
                        font-weight: 400;
                      "
                      v-if="scope.row.buyType == '1'"
                      >正常订购</span
                    >
                    <span
                      style="
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        color: #128bed;
                        font-weight: 400;
                      "
                      v-if="scope.row.buyType == '2'"
                      >后台添加</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="buyProductPrice"
                  label="价格(元)"
                  align="center"
                ></el-table-column>
              </el-table>
            </div>
            <div v-if="sideBarIndex == 3">
              <p
                style="
                  width: 96%;
                  margin: 0px auto;
                  padding: 20px 0;
                  border-bottom: 1px solid #eaeaea;
                  font-family: PingFangSC-Semibold;
                  font-size: 18px;
                  color: #424242;
                  font-weight: 600;
                "
              >
                使用记录
              </p>
              <el-table
                :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                :data="usageData"
                style="width: 96%; margin: 0px auto"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="useProductName"
                  label="产品名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="useTime"
                  label="使用时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="useResultStatus"
                  label="使用状态"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span
                      style="
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        color: #128bed;
                        font-weight: 400;
                      "
                      v-if="scope.row.useResultStatus == '1'"
                      >成功</span
                    >
                    <span
                      style="
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        color: #128bed;
                        font-weight: 400;
                      "
                      v-if="scope.row.useResultStatus == '2'"
                      >失败</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userName"
                  label="用户"
                  align="center"
                ></el-table-column>
              </el-table>
              <div
                style="
                  text-align: center;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-pagination
                  @size-change="handleUseSizeChange"
                  @current-change="handleUseCurrentChange"
                  :current-page="usageParams.pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="usageParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="useTotal"
                >
                </el-pagination>
              </div>
            </div>
            <div v-if="sideBarIndex == 4">
              <p
                style="
                  width: 96%;
                  margin: 0px auto;
                  padding: 20px 0;
                  border-bottom: 1px solid #eaeaea;
                  font-family: PingFangSC-Semibold;
                  font-size: 18px;
                  color: #424242;
                  font-weight: 600;
                "
              >
                浏览历史
              </p>
              <el-table
                :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                :data="historyList"
                style="width: 96%; margin: 0px auto"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="businessName"
                  label="企业名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="legalPersion"
                  label="法人"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="registerDate"
                  label="企业登记时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="businessEmail"
                  label="企业邮箱"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="businessTelephone"
                  label="企业电话"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="businessAddress"
                  label="企业地址"
                  align="center"
                ></el-table-column>
              </el-table>
              <div
                style="
                  text-align: center;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-pagination
                  @size-change="handleHistorySizeChange"
                  @current-change="handleHistoryCurrentChange"
                  :current-page="historyParams.pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="historyParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="historyTotal"
                >
                </el-pagination>
              </div>
            </div>
            <div v-if="sideBarIndex == 6">
              <p
                style="
                  width: 96%;
                  margin: 0px auto;
                  padding: 20px 0;
                  border-bottom: 1px solid #eaeaea;
                  font-family: PingFangSC-Semibold;
                  font-size: 18px;
                  color: #424242;
                  font-weight: 600;
                "
              >
                我的收藏
              </p>
              <el-table
                :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                :data="collectionList"
                style="width: 96%; margin: 0px auto"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="buyProductName"
                  label="产品名称"
                  align="center"
                ></el-table-column>
              </el-table>
              <div
                style="
                  text-align: center;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-pagination
                  @size-change="handleCollectSizeChange"
                  @current-change="handleCollectCurrentChange"
                  :current-page="collectParams.pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="collectParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="collectTotal"
                >
                </el-pagination>
              </div>
            </div>
          </el-row>
        </el-main>
      </el-container>
    </el-container>

    <foot></foot>
  </div>
</template>

<script>
import { isMobile, isEmail } from "@/plugins/validate";
import foot from "@/components/footer/footer";
import {
  removeHeaderToken,
  getHeaderToken,
  getUserId,
  removeUserId,
} from "../../plugins/auth";

export default {
  name: "Home",
  components: {
    foot,
  },
  data() {
    var checkID = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入身份证号"));
      } else if (
        /^\d{17}x$/.test(this.enterprise.legalpersonid) ||
        /^\d{18}$/.test(this.enterprise.legalpersonid)
      ) {
        callback();
      } else {
        callback(new Error("身份证号码不正确,请检查后重新输入"));
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.pwdForm.password !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    const equalToPassword = (rule, value, callback) => {
      if (this.pwdForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };

    return {
      useTotal: 0,
      historyTotal: 0,
      collectTotal: 0,
      collectParams: {
        pageNum: 1,
        pageSize: 10,
      },

      //企业认证表单校验
      rules: {
        businessName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        legalperson: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        legalpersonid: [
          { required: true, validator: checkID, trigger: "blur" },
        ],
        productImg: [
          { required: true, message: "请上传身份证照片", trigger: "blur" },
        ],
        businessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
      fileList1: [],
      fileList2: [], //文件上传列表
      fileList: [], //文件上传列表
      avatarUploadUrl: "", // 用户头像上传接口
      liscenseUploadUrl: "", // 营业执照图片上传接口
      searchList: [
        { id: 1, name: "查企业" },
        { id: 2, name: "查老板" },
      ],
      tabBarList: [
        { id: 1, name: "快捷登录" },
        { id: 2, name: "密码登录" },
      ],
      tabIndex: 0,
      phone: "",
      changeTabItem: 0,
      dialogVisible: false,
      activeName: "first",
      headerShow: false,
      sideBarIndex: 1,
      tableParams: {},
      tableData: [],
      userData: {},
      //使用记录
      usageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      usageData: [],
      //个人信息
      headImg: "",
      userForm: {
        username: "",
        phone: "",
        email: "",
        realName: "",
        headImg: "",
      },
      phoneShow: false,
      emailShow: false,
      //密码修改
      pwdForm: {
        password: "",
        confirmPwd: "",
        verifyCode: "",
        code: "",
      },
      pwdFormRules: {
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
          {
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            message: "请输入8位以上包括大小写字母、数字及特殊字符组合的密码",
          },
        ],
        confirmPwd: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      getCodeText: "获取验证码",
      userPhone: "",
      isDisabled: false,
      pwdPhoneShow: false,
      pwdTips: "",
      //企业信息修改
      enterprise: {
        businessLicense: "",
        productImg: "",
      },
      IdShow: false,
      collectionList: [],
      //浏览历史
      historyList: [],
      historyParams: {
        pageNum: 1,
        pageSize: 10,
      },
      approveOptions: [
        {
          dictLabel: "营业执照已过期",
          dictValue: "0",
        },
        {
          dictLabel: "认证审核中",
          dictValue: "1",
        },
        {
          dictLabel: "认证成功",
          dictValue: "2",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.avatarUploadUrl =
      "http://" + window.location.host + "/se/user/avatar/upload";
    this.licenseUploadUrl =
      "http://" + window.location.host + "/se/user/license/upload";
    this.getLocal();
  },
  activated() {
    this.getLocal();
  },
  mounted() {},
  methods: {
    // getPersonalInfo(){
    //     this.$axios.get("/user/annualById?id=" + this.userData.id).then(res => {
    //
    //         this.userForm.username = res.data.data.username;
    //         this.userForm.phone = res.data.data.phone;
    //         this.userForm.email = res.data.data.email;
    //         this.userForm.realName =res.data.data.realName;
    //
    //
    //     })
    // },
    submitUpload1() {
      this.$refs.upload1.submit();
    },
    removeCardHead() {
      this.enterprise.legalpersonCardHead = "";
    },
    httpRequest1(params) {
      let formdata = new FormData();
      formdata.append("file", params.file);
      this.$axios.post("/common/upload", formdata).then((res) => {
        if (res.data.code == 200) {
          // this.fileList1 = []
          this.enterprise.legalpersonCardHead = res.data.url;
          this.enterprise.productImg = "flag";
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
        } else {
          this.$message.error("上传失败");
        }
      });
    },

    submitUpload2() {
      this.$refs.upload2.submit();
    },
    removeCardTail() {
      this.enterprise.legalpersonCardTail = "";
    },
    httpRequest2(params) {
      let formdata = new FormData();
      formdata.append("file", params.file);
      this.$axios.post("/common/upload", formdata).then((res) => {
        // console.log(res.data)
        if (res.data.code == 200) {
          // this.fileList2 = []
          this.enterprise.legalpersonCardTail = res.data.url;
          this.enterprise.productImg = "flag";
          this.$message.success("上传成功");
          this.IsUploadSuccess = true;
        } else {
          this.$message.error("上传失败");
        }
      });
    },

    handleClick(tab, event) {
      if (tab.label == "企业认证") {
        this.$axios
          .get("/user/annualById?id=" + this.userData.id)
          .then((res) => {
            this.enterprise = res.data.data;
            if (
              this.enterprise.legalpersonCardHead ||
              this.enterprise.legalpersonCardTail
            ) {
              this.enterprise.productImg = "flag";
            } else {
              this.enterprise.productImg = "";
            }
            this.enterprise.productImg = "flag";
            if (this.enterprise.auditStatus == "2") {
              this.enterprise.auditStatus = "审核成功";
            } else if (this.enterprise.auditStatus == "1") {
              this.enterprise.auditStatus =
                "审核不通过，原因：" + this.enterprise.reason;
            } else {
              this.enterprise.auditStatus = "未审核";
            }
          });
      }
    },

    changeList(index) {
      this.tabIndex = index;
    },
    dialogVisibleShow() {
      this.dialogVisible = false;
    },
    changeTab(index) {
      this.changeTabItem = index;
    },
    //检验是否登录
    getLocal() {
      this.userForm.headImg = "";
      if (getHeaderToken()) {
        this.headerShow = false;
        this.$axios.get("/user/annualById?id=" + getUserId()).then((res) => {
          let currentPhone = res.data.data.phone;
          this.userData = res.data.data;
          this.headImg = this.userData.headImg;
          this.phone = this.userData.phone;
          this.userPhone = this.userData.phone;
          this.userData.phone = "" + this.userData.phone;
          this.userData.phone = this.userData.phone.replace(
            this.userData.phone.substring(3, 7),
            "****"
          );
          this.userForm.username = res.data.data.username;

          this.userForm.phone = currentPhone;
          this.userForm.email = res.data.data.email;
          this.userForm.realName = res.data.data.realName;
          this.userForm.headImg = res.data.data.headImg;
        });
      } else {
        this.userData = {};
        this.headerShow = true;
      }
    },
    goShouYe() {
      this.$router.push("/");
    },
    changeSideBar(e) {
      let _this = this;
      _this.sideBarIndex = e;
      // console.log(this.sideBarIndex)

      switch (_this.sideBarIndex) {
        case "2":
          _this.getTableData(); //订购记录
          break;
        case "3":
          _this.getUsageData(); //使用记录
          break;
        case "4":
          _this.getHistory(); //浏览历史
          break;
        case "6":
          _this.getCollectionList(); //我的收藏
          break;
      }
    },
    //个人信息中上传头像
    avtarUpload(params) {
      let formdata = new FormData();
      formdata.append("file", params.file);
      this.$axios.post("/common/upload", formdata).then((res) => {
        // console.log(res.data)
        if (res.data.code == 200) {
          // this.fileList2 = []
          this.userForm.headImg = res.data.url;
          this.$message.success("上传头像成功");
          this.IsUploadSuccess = true;
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    removeAvatar() {
      this.userForm.headImg = "";
    },
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      if (!testmsg) {
        this.$message.error("只能上传jpg/png格式");
      }
      return isLt1M && testmsg;
    },
    //验证个人信息中的手机号
    checkPhone() {
      if (isMobile(this.userForm.phone)) {
        this.phoneShow = false;
      } else {
        this.phoneShow = true;
      }
    },

    //验证email
    checkEmail() {
      if (isEmail(this.userForm.email)) {
        this.emailShow = false;
      } else {
        this.emailShow = true;
      }
    },
    //个人信息保存
    userSubmit() {
      this.userForm.id = this.userData.id;

      this.$axios
        .post("/user/editUser", this.userForm)
        .then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "修改个人信息成功",
              type: "success",
            });
            // this.resetUserForm();
            this.getLocal();
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //重置个人信息
    resetUserForm() {
      this.userForm = {
        username: "",
        phone: "",
        email: "",
        realName: "",
        headImg: null,
      };
    },
    //验证密码修改中的手机号
    checkPwdPhone() {
      if (isMobile(this.pwdForm.phone)) {
        this.pwdPhoneShow = false;
      } else {
        this.pwdPhoneShow = true;
      }
    },

    getPhoneCode() {
      let _this = this;
      let holdTime = 60;
      var timer = setInterval(() => {
        if (holdTime > 0) {
          holdTime--;
          _this.getCodeText = holdTime + "s";
          _this.isDisabled = true;
        }
        if (holdTime <= 0) {
          _this.isDisabled = false;
          _this.getCodeText = "重新获取";
          clearInterval(timer);
        }
      }, 1000);

      this.$axios
        .get("user/sendPhoneMsg?phone=" + this.userPhone)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.pwdForm.code = res.data.msg;
            this.$message.success("发送成功");
          } else {
            this.$message.error("发送失败");
          }
        });
    },

    resetForm() {
      this.$refs["pwdForm"].resetFields();
    },

    //密码修改提交
    pwdSubmit() {
      this.$refs["pwdForm"].validate((valid) => {
        if (valid) {
          this.pwdForm.id = this.userData.id;
          this.$axios
            .post("/user/editPwd", this.pwdForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "修改密码成功",
                  type: "success",
                });
                this.resetPwdForm();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        }
      });
    },
    //重置密码修改
    resetPwdForm() {
      this.pwdForm = {
        oldPwd: "",
        phone: "",
        password: "",
        confirmPwd: "",
      };
    },
    //企业认证成功上传营业执照
    successUpload(res) {
      let p2 = { path: this.userData.businessLicense };
      if (res.data.code == 200) {
        this.enterprise.businessLicense = res.data.data.virtualPath;
        this.fileList = [];
      }
    },

    httpRequest(params) {
      let formdata = new FormData();
      formdata.append("file", params.file);
      this.$axios.post("/common/upload", formdata).then((res) => {
        // console.log(res.data)
        if (res.data.code == 200) {
          // this.fileList = []
          this.enterprise.businessLicense = res.data.url;
          this.$message.success("上传成功");
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    removeBusinessLicense() {
      this.enterprise.businessLicense = "";
    },
    //上传图片到服务器
    submitUpload() {
      this.$refs.upload.submit();
    },
    //三要素核验
    enterpriseSubmit() {
      let checkdata = {
        certId: this.enterprise.legalpersonid,
        userName: this.enterprise.legalperson,
        phoneNo: this.phone,
      };
      this.saveEnterprise();
      // this.$axios.post("check/checkStatus", checkdata).then((res) => {
      //   if (res.data.code == "000000") {
      //     if (res.data.checkResult == "01") {
      //       this.saveEnterprise();
      //     } else if (res.data.checkResult == "02") {
      //       this.$message.error("您输入的信息不匹配，请确保所填信息为同一人");
      //       return;
      //     }
      //   } else {
      //     this.$message.error("您输入的信息不正确，请检查后重新输入");
      //     return;
      //   }
      // });
    },
    //企业信息修改
    saveEnterprise() {
      this.enterprise.id = this.userData.id;
      if (
        this.enterprise.legalpersonCardHead &&
        this.enterprise.legalpersonCardTail &&
        this.enterprise.businessLicense
      ) {
        this.$refs["enterpriseForm"].validate((valid) => {
          if (valid) {
            this.$axios
              .post("/user/businessAuth", this.enterprise)
              .then((res) => {
                if (res.data.code == 200) {
                  this.$notify({
                    title: "成功",
                    message: "修改企业信息成功",
                    type: "success",
                  });
                  this.fileList = [];
                  this.getLocal();
                  // this.resetEnterprise();
                }
              })
              .catch((err) => {});
          }
        });
      } else {
        this.$notify({
          title: "提示",
          message: "请上传身份证照片和企业营业执照",
          type: "warning",
        });
      }
    },
    //重置企业信息
    resetEnterprise() {
      this.enterprise = {
        businessName: "",
        businessCode: "",
        businessRegisterTime: null,
        businessLicense: "",
      };
    },
    //上传图片验证
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      if (!testmsg) {
        this.$message.error("只能上传jpg/png格式");
      }
      return isLt1M && testmsg;
    },

    //   uploadLicense(param){
    //       let formData = new FormData();
    //       formData.append("file", param.file);
    //       formData.append("name", param.file.name);
    //       let config = {
    //           headers:{
    //               'content-type':'multipart/form-data'
    //           }
    //       }
    //       this.$axios.post('/common/upload',formData,config).then(res=>{
    //           console.log(res)
    //           if(res.data.code == 200){
    //               this.enterprise.businessLicense = res.data.url
    //           }
    //           console.log(this.userForm)
    //       }).catch(err=>{
    //           console.log(err)
    //       })
    //   },
    //订购记录
    getTableData() {
      this.tableParams.userId = this.userData.id;
      this.tableParams.status = 1;
      this.$axios
        .post("/userProduct/getUserProduct", this.tableParams)
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.data;
            // console.log(this.tableData);
          }
        });
    },

    handleUseSizeChange(res) {
      this.usageParams.pageSize = res;
      this.getUsageData();
    },

    handleUseCurrentChange(res) {
      this.usageParams.pageNum = res;
      this.getUsageData();
    },

    // 使用记录
    getUsageData() {
      this.usageParams.userName = this.userData.username;
      this.$axios
        .post("/useProduct/getUseProductRecord", this.usageParams)
        .then((res) => {
          if (res.data.code == 200) {
            this.usageData = res.data.data.rows;
            this.useTotal = res.data.data.total;
          }
        });
    },
    handleHistorySizeChange(res) {
      this.historyParams.pageSize = res;
      this.getHistory();
    },

    handleHistoryCurrentChange(res) {
      this.historyParams.pageNum = res;
      this.getHistory();
    },
    //获取浏览历史
    getHistory() {
      this.historyParams.userId = this.userData.id;
      this.$axios
        .post("/history/getBrowseHistory", this.historyParams)
        .then((res) => {
          if (res.data.code == 200) {
            this.historyList = res.data.data.rows;
            this.historyTotal = res.data.data.total;
          }
        });
    },
    //退出登录
    outLogin() {
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
          this.$router.push("/");
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },

    handleCollectSizeChange(res) {
      this.collectParams.pageSize = res;
      this.getCollectionList();
    },
    handleCollectCurrentChange(res) {
      this.collectParams.pageNum = res;
      this.getCollectionList();
    },
    //获取收藏列表
    getCollectionList() {
      this.collectParams.userId = this.userData.id;
      this.$axios
        .get(
          "/userProduct/getcollectionProduct?id=" +
            this.userData.id +
            "&pageNum=" +
            this.collectParams.pageNum +
            "&pageSize=" +
            this.collectParams.pageSize
        )
        .then((res) => {
          this.collectionList = res.data.data.rows;
          this.collectTotal = res.data.data.total;
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .avatarWrapper .el-upload-list {
  display: inline-block;
}
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: none;
    float: left;
    margin-left: 20px;
}

.all.register-wrapper .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.all.register-wrapper .avatar-uploader {
  display: inline-block;
  margin: 0 10px;
}
::v-deep .el-upload.el-upload--text {
  line-height: 0px;
}

::v-deep .el-upload__tip {
  text-align: center;
  font-size: 12px;
  margin-top: 0;
}

.all.register-wrapper .avatar-uploader .cert {
  width: 120px;
  height: 88px;
}

.pane .el-input-group__append button.el-button {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: inherit;
}
.validate-code-wrapper .el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.home {
  background: #f5f7fa;
}

.sideBar {
  width: 216px !important;
  height: 580px;
  background: #ffffff;
  padding-top: 1%;
}

::v-deep .el-menu-vertical {
  text-align: center;
  border-right: none;
}

.item {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  font-weight: 500;
}

.group2 {
  border-top: 1px solid #d8d8d8;
}

.main {
  //margin-top: 65px;
  margin-left: 10px;
  width: 960px;
  background: #ffffff;
}

.cert {
  width: 120px;
  height: 88px;
}

.pane {
  background: #ffffff;
}

ul {
  display: block;
}

.basic img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.containerTop {
  margin-left: 30px;
  // width: 930px;
  height: 120px !important;
  background: #ffffff;
  display: flex;
  align-items: center;
}

.containerTopText {
  display: block;
  padding: 6px 10px;
  background: rgba(18, 139, 237, 0.2);
  border-radius: 2px;
  margin-top: -20px;
  margin-left: 20px;
}

::v-deep .el-tabs--top {
  padding: 20px;
}

::v-deep .el-main {
  overflow: hidden;
}

::v-deep .el-row {
  width: 100%;
}

::v-deep .el-input__inner {
  margin-top: 0 !important;
}

::v-deep .el-form-item__label {
  height: 40px;
}

::v-deep .el-input {
  margin-top: 0 !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

::v-deep .el-form-item {
  margin-bottom: 0;
  margin-top: 20px;
}

.tipsText {
  color: red;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-indent: 86px;
  font-size: 14px;
}

.loginOut {
  position: relative;
  top: 3vh;
  left: 28vw;
  color: #128bed;
  cursor: pointer;
}
.newlogin {
  display: flex;
}
.loginOutNew {
  &:hover {
    color: #0060ff !important;
    background: rgba(18, 139, 237, 0) !important;
  }
}
</style>
<style lang="less">
.contentMid {
  padding: 0 18.8%;
  margin-top: 30px;
}
</style>
